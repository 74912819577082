export function makePath() {
  if (arguments?.length) return `/${Array.from(arguments).filter(Boolean).join('/')}`;
  else return '';
}

// HOME ----------------------------

export const PATH_HOME = '/';

// VERIFY ----------------------------------------------------------------------

export const VERIFY_ROOT = 'verify';
// root is used with token /verify/:token
export const VERIFY_NO_TOKEN = 'no-token';
export const VERIFY_ERROR = 'error';
export const VERIFY_BLOCKED = 'blocked';

export const PATH_VERIFY = {
  getVerifyRootRoute: function (client_key, verification_token = VERIFY_NO_TOKEN, membership_ref = '') {
    return makePath(VERIFY_ROOT, client_key, verification_token, membership_ref);
  },
  getVerifyErrorRoute: function (client_key, verification_token = VERIFY_NO_TOKEN, membership_ref = '') {
    return makePath(VERIFY_ROOT, client_key, verification_token, membership_ref, VERIFY_ERROR);
  },
  getVerifyBlockedRoute: function (client_key, verification_token = VERIFY_NO_TOKEN, membership_ref = '') {
    return makePath(VERIFY_ROOT, client_key, verification_token, membership_ref, VERIFY_BLOCKED);
  },
};

// MEMBERSHIP ----------------------------------------------------------------------

export const MEMBERSHIP_ROOT = 'membership';
export const MEMBERSHIP_CHOOSE_TYPE = 'choose-membership';
export const MEMBERSHIP_CHOOSE_VENUE = 'choose-venue';
export const MEMBERSHIP_PAY = 'pay';
export const MEMBERSHIP_PAY_SUCCESS = 'success';
export const MEMBERSHIP_PAY_ERROR = 'error';

export const PATH_MEMBERSHIP = {
  root: MEMBERSHIP_ROOT,
  chooseMembership: makePath(MEMBERSHIP_ROOT, MEMBERSHIP_CHOOSE_TYPE),
  getChooseVenueRoute: function (new_membership_type, action) {
    return makePath(MEMBERSHIP_ROOT, new_membership_type, action, MEMBERSHIP_CHOOSE_VENUE);
  },
  getMembershipPayRoute: function (new_membership_type, action, venue) {
    return makePath(MEMBERSHIP_ROOT, new_membership_type, action, venue, MEMBERSHIP_PAY);
  },
  getMembershipPaySuccess: function (new_membership_type, action, venue) {
    return makePath(MEMBERSHIP_ROOT, new_membership_type, action, venue, MEMBERSHIP_PAY, MEMBERSHIP_PAY_SUCCESS);
  },
  getMembershipPayError: function (new_membership_type, action, venue) {
    return makePath(MEMBERSHIP_ROOT, new_membership_type, action, venue, MEMBERSHIP_PAY, MEMBERSHIP_PAY_ERROR);
  },
  getMembershipError: function () {
    return makePath(MEMBERSHIP_ROOT, MEMBERSHIP_PAY_ERROR);
  },
};

// PAYMENT METHODS ----------------------------------------------------------------------

export const PAYMENT_METHODS_ROOT = 'payment-methods';
export const PAYMENT_METHODS_ADD = 'add';
export const PAYMENT_METHODS_ERROR = 'error';

export const PATH_PAYMENT_METHODS = {
  root: PAYMENT_METHODS_ROOT,
  add: makePath(PAYMENT_METHODS_ROOT, PAYMENT_METHODS_ADD),
  error: PAYMENT_METHODS_ERROR,
};
