import React from 'react';
import PropTypes from 'prop-types';
import './Chevrons.styles.scss';

import ChevronsSVG from 'assets/chevrons.svg';

const Chevrons = (props) => {
  return <img data-testid="ErrorImage" src={ChevronsSVG} alt="chevrons" className="max-w-none h-[100px]" />;
};

Chevrons.propTypes = {};

export default Chevrons;
