import React from 'react';
import PropTypes from 'prop-types';
import LogoSVG from 'assets/teamsport-logo.svg';

const Logo = ({ height }) => {
  return <img data-testid="Logo" src={LogoSVG} alt="Logo" className={`w-full ${height}`} />;
};

Logo.propTypes = {
  height: PropTypes.string,
};

export default Logo;
