import React from 'react';
import PropTypes from 'prop-types';

import { sizeVariants, textColourVariants } from 'constants/typography';
import './Price.styles.scss';

const Price = ({ price, size = 'lg', colour }) => {
  const priceTextSizeVariant = {
    xs: 'text-xs',
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-sm',
    xl: 'text-md',
    '2xl': 'text-md',
    '3xl': 'text-lg',
    '4xl': 'text-lg',
  };
  return (
    <div data-testid="Price" className={`flex items-end ${textColourVariants[colour]}`}>
      <p className={`${sizeVariants[size]} leading-[1rem] font-bold`}>{price}</p>
      <p className={`${priceTextSizeVariant[size]} leading-none`}>/YR</p>
    </div>
  );
};

Price.propTypes = {
  colour: PropTypes.oneOf(['social', 'club', 'elite', 'white', 'gray']),
  price: PropTypes.string,
  size: PropTypes.string,
};

export default Price;
