import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { PaymentElement, AddressElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Button from 'components/Button/Button';

import { PATH_MEMBERSHIP, PATH_PAYMENT_METHODS } from 'routes/paths';
import { setMembershipRefKey } from 'utils/localStorage';

import { useAuthContext } from 'hooks/useAuthContext';

import { PAYMENT_ACTIONS } from 'constants/payments';

import './StripeNewCard.styles.scss';

const StripeForm = ({ variant, stripeSetup, redirectTo }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { payment_methods, setMembershipRef } = useAuthContext();
  const { new_membership_type, action, venue } = useParams();

  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const btnText =
    stripeSetup === 'se' ? (payment_methods?.length ? 'Update Payment Method' : 'Add Payment Method') : 'Pay now';

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    try {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return null;
      }
      const stripeMethod = stripeSetup === 'se' ? stripe.confirmSetup : stripe.confirmPayment;
      setIsLoading(true);
      setMembershipRef(new_membership_type);
      setMembershipRefKey(new_membership_type);
      const response = await stripeMethod({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url:
            stripeSetup === 'se'
              ? `${window.location.origin}/${PATH_PAYMENT_METHODS.root}`
              : `${window.location.origin}${PATH_MEMBERSHIP.getMembershipPaySuccess(
                  new_membership_type,
                  action,
                  venue
                )}`,
        },
      });

      const { error } = response;
      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setMembershipRef('');
        setMembershipRefKey('');
        navigate(PATH_MEMBERSHIP.getMembershipPayError(new_membership_type, PAYMENT_ACTIONS.new, venue), {
          state: {
            title: 'Something went wrong when making payment',
            retryUrl: `/${PATH_MEMBERSHIP.getMembershipPayRoute(new_membership_type, PAYMENT_ACTIONS.new, venue)}`,
          },
        });
        throw error;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        // navigate(redirectTo);
      }
    } catch (err) {
      navigate(PATH_MEMBERSHIP.getMembershipPayError(new_membership_type, PAYMENT_ACTIONS.new, venue), {
        state: {
          title: err?.error_message || 'Something went wrong when making payment',
          retryUrl: `/${PATH_MEMBERSHIP.getMembershipPayRoute(new_membership_type, PAYMENT_ACTIONS.new, venue)}`,
        },
      });
    } finally {
      // change this later
      // navigate(redirectTo);

      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 md:p-12 rounded-lg">
      <AddressElement options={{ mode: 'billing' }} />
      <div className="pb-4" />
      <PaymentElement />
      <div className="mt-4">
        <Button
          text={btnText}
          variant="standard"
          colour={variant}
          fullWidth
          overrideStyles="rounded-md"
          btnType="submit"
          isLoading={isLoading}
        />
      </div>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

StripeForm.propTypes = {
  variant: PropTypes.oneOf(['social', 'club', 'elite']),
  stripeSetup: PropTypes.oneOf(['pi', 'se']),
  redirectTo: PropTypes.string,
  price: PropTypes.string,
};

export default StripeForm;
