import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

// guards
import AuthGuard from 'guards/AuthGuard';

// layouts
import TestLayout from 'layouts/TestLayout';

// pages
import Verify from 'pages/verify/Verify';
import MyMembership from 'pages/membership/MyMembership';
import MembershipChooseVenue from 'pages/membership/MembershipChooseVenue';
import MembershipChooseType from 'pages/membership/MembershipChooseType';
import MembershipMakePayment from 'pages/membership/MembershipMakePayment';
import MembershipPaymentSuccess from 'pages/membership/MembershipPaymentSuccess';

import PaymentMethods from 'pages/payments/PaymentMethods';
import PaymentMethodsAdd from 'pages/payments/PaymentMethodsAdd';

// config
import { PATH_MEMBERSHIP, PATH_PAYMENT_METHODS, PATH_VERIFY } from './paths';
import MembershipLayout from 'layouts/MembershipLayout';
import Error from 'components/Error/Error';

// ----------------------------------------------------------------------

export default function Router() {
  const elements = useRoutes([
    // home - redirect to verify/no-token
    {
      path: '/',
      element: <Navigate to={PATH_VERIFY.getVerifyRootRoute('no-token')} replace />,
    },
    // verify
    {
      path: PATH_VERIFY.getVerifyRootRoute(':client_key', ':verification_token'),
      element: <TestLayout />,
      children: [
        { element: <Verify />, index: true },
        {
          path: PATH_VERIFY.getVerifyRootRoute(':client_key', ':verification_token', ':membership_ref'),
          element: <Verify />,
        },
        {
          path: PATH_VERIFY.getVerifyErrorRoute(':client_key', ':verification_token', ':membership_ref?'),
          element: <Error center />,
        },
        {
          path: PATH_VERIFY.getVerifyBlockedRoute(':client_key', ':verification_token', ':membership_ref?'),
          element: <Error title="You seem to be blocked" center />,
        },
      ],
      errorElement: <Error title="Something went wrong" center />,
    },
    // membership
    {
      path: PATH_MEMBERSHIP.root,
      element: (
        <AuthGuard>
          <MembershipLayout />
        </AuthGuard>
      ),
      children: [
        { element: <MyMembership />, index: true },
        {
          path: PATH_MEMBERSHIP.chooseMembership,
          element: <MembershipChooseType />,
        },
        {
          path: PATH_MEMBERSHIP.getChooseVenueRoute(':new_membership_type', ':action'),
          element: <MembershipChooseVenue />,
        },
        {
          path: PATH_MEMBERSHIP.getMembershipPayRoute(':new_membership_type', ':action', ':venue'),
          element: <MembershipMakePayment />,
        },
        {
          path: PATH_MEMBERSHIP.getMembershipPaySuccess(':new_membership_type', ':action', ':venue'),
          element: <MembershipPaymentSuccess />,
        },
        {
          path: PATH_MEMBERSHIP.getMembershipPayError(':new_membership_type', ':action', ':venue'),
          element: <Error retryUrl={PATH_MEMBERSHIP.root} />,
        },
        {
          path: PATH_MEMBERSHIP.getMembershipError(),
          element: <Error />,
        },
      ],
    },
    // payment methods
    {
      path: PATH_PAYMENT_METHODS.root,
      element: (
        <AuthGuard>
          <MembershipLayout />
        </AuthGuard>
      ),
      children: [
        { element: <PaymentMethods />, index: true },
        {
          path: PATH_PAYMENT_METHODS.add,
          element: <PaymentMethodsAdd />,
        },
        {
          path: PATH_PAYMENT_METHODS.error,
          element: <Error />,
        },
      ],
    },

    // unknown routes - redirect to verify/no-token
    {
      path: '*',
      element: <Navigate to={PATH_VERIFY.getVerifyRootRoute('no-token')} replace />,
    },
  ]);
  const location = useLocation();
  if (!elements) return null;
  return <AnimatePresence mode="wait">{React.cloneElement(elements, { key: location.pathname })}</AnimatePresence>;
}
