import React from 'react';
import PropTypes from 'prop-types';
import './KartImage.styles.scss';

import Racer from 'assets/racer.png';

const KartImage = ({ width = '100%' }) => {
  return <img data-testid="KartImage" src={Racer} alt="racer" width={width} className="max-w-none" />;
};

KartImage.propTypes = {
  width: PropTypes.string,
};

export default KartImage;
