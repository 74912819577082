import React from 'react';
import PropTypes from 'prop-types';
import './ButtonList.styles.scss';
import ButtonListItem from 'components/ButtonListItem/ButtonListItem';

const ButtonList = ({ list, onClick, variant, selected }) => {
  return (
    <ul data-testid="ButtonList" className="flex flex-col flex-1">
      {list?.map((item, i) => (
        <li key={`${item?.client_key}_${i}`} className="mb-2">
          <ButtonListItem
            text={item?.name}
            onClick={() => onClick(item?.client_key)}
            variant={variant}
            isSelected={selected === item.client_key}
          />
        </li>
      ))}
    </ul>
  );
};

ButtonList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string, city: PropTypes.string })),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['social', 'club', 'elite']),
  selected: PropTypes.string,
};

export default ButtonList;
