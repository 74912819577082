import React from 'react';
import PropTypes from 'prop-types';

import CardListItem from 'components/CardListItem/CardListItem';
import './CardList.styles.scss';

const CardList = ({ list, variant }) => {
  return (
    <ul data-testid="CardList">
      {list?.map((item) => (
        <CardListItem variant={variant} text={item} key={item} />
      ))}
    </ul>
  );
};

CardList.propTypes = {
  list: PropTypes.array,
  variant: PropTypes.oneOf(['social', 'club', 'elite']),
};

export default CardList;
