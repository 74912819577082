import './fonts/Bebas/Bebas-Neue-Pro-Bold.otf';
import './fonts/Bebas/Bebas-Neue-Pro-Regular.otf';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './styles/_index.scss';
import App from './App';

import reportWebVitals from './reportWebVitals';

// Enable redirect for S3
const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) history.replace(path);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
