import React from 'react';
import PropTypes from 'prop-types';
import './AddPaymentMethod.styles.scss';

import PlusButton from 'assets/plus-button.svg';

const AddPaymentMethod = ({ onClick }) => {
  return (
    <button
      data-testid="AddPaymentMethod"
      className="border-2 border-gray w-[300px] h-[168px] rounded-lg p-6 flex flex-col items-center justify-center text-gray hover:opacity-70"
      onClick={onClick}
    >
      <div className="text-center">
        <img src={PlusButton} alt="plus-button" className="mx-auto m-2 w-[55px]" />
        <p>Update payment method</p>
      </div>
    </button>
  );
};

AddPaymentMethod.propTypes = {
  onClick: PropTypes.func,
};

export default AddPaymentMethod;
