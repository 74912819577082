import React from 'react';
import PropTypes from 'prop-types';

import PaymentCardField from './PaymentCardField';
import MasterCard from 'assets/mastercard.svg';
import MinusButton from 'assets/minus-button.png';

import { isAfter } from 'date-fns';

import './PaymentCard.styles.scss';

const PaymentCard = ({ number, name, expiry, payment_method_id, onRemoveCard }) => {
  const isExpired = isAfter(new Date(), new Date(`01/${expiry}`));

  return (
    <div className={`flex flex-col w-[300px]`}>
      <div
        data-testid="PaymentCard"
        className={`bg-gray w-full h-[168px] rounded-lg p-6 flex flex-col justify-between text-left ${isExpired ? 'border-2 border-warning' : ''}`}
      >
        <img src={MasterCard} alt="mastercard-icon" className="h-[30px] w-[50px]" />
        <p className="text-lg my-2">{number}</p>
        <div className="flex justify-between items-end ">
          <PaymentCardField label="Card holder" value={name} />
          <PaymentCardField label="Expiry date" value={expiry} isExpired={isExpired}/>
        </div>
      </div>
      {/* {onRemoveCard ? (
        <button onClick={() => onRemoveCard(payment_method_id)} className="mt-2">
          <img src={MinusButton} alt="plus-button" className="mx-auto m-2 w-[30px]" />
        </button>
      ) : null} */}
    </div>
  );
};

PaymentCard.propTypes = {
  number: PropTypes.string,
  name: PropTypes.string,
  expiry: PropTypes.string,
  payment_method_id: PropTypes.string,
  onRemoveCard: PropTypes.func,
};

export default PaymentCard;
