import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import BodyText from 'components/BodyText/BodyText';
import Divider from 'components/Divider/Divider';
import MembershipCardGroup from 'components/MembershipCardGroup/MembershipCardGroup';
import SingleColumnWrapper from 'components/SingleColumnWrapper/SingleColumnWrapper';
import { getMemberships } from 'api';
import { useAuthContext } from 'hooks/useAuthContext';
import { PATH_MEMBERSHIP } from 'routes/paths';
import { useLoadProfile } from 'hooks/useLoadProfile';

const MembershipChooseType = (props) => {
  const { pathname } = useLocation();

  const { auth_token, subscription, setMemberships } = useAuthContext();
  const navigate = useNavigate();

  useLoadProfile();

  useEffect(() => {
    if (subscription) {
      navigate(`/${PATH_MEMBERSHIP.root}`);
    }
  }, [subscription]);

  return (
    <SingleColumnWrapper styleOverrides="md:max-w-[1100px]">
      <div data-testid="NoMembership" className="w-full">
        <div className="max-w-[300px] md:max-w-[700px] mx-auto relative md:mb-12">
          <BodyText colour="elite" text="YOU CURRENTLY HAVE NO MEMBERSHIP PLAN" size="3xl" uppercase tracking="1.2px" />
          <div className="my-2">
            <Divider />
          </div>
          <BodyText
            colour="white"
            weight="book"
            text="BECOME A MEMBER AND ENJOY EXCLUSIVE BENEFITS TODAY."
            size="xl"
            tracking="2.4px"
          />
        </div>
        <div className="mt-6 md:p-4 w-screen md:w-full left-0 absolute md:static">
          <MembershipCardGroup />
        </div>
      </div>
    </SingleColumnWrapper>
  );
};

MembershipChooseType.propTypes = {};

export default MembershipChooseType;
