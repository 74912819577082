import { useEffect, useState, useCallback} from "react";
import { useNavigate, useLocation } from 'react-router';

import { useAuthContext } from "./useAuthContext";

import { getSubscriptionKey, setSubscriptionKey } from "utils/localStorage";

import { PATH_MEMBERSHIP } from "routes/paths";

import { getUserProfile } from "api";

export const useLoadProfile = () => {
    const {
        subscription,
        auth_token,
        client_key,
        membership_ref,
        setProfile,
        memberships,
      } = useAuthContext();
      const navigate = useNavigate();
      const { pathname } = useLocation();

    const [isProfileLoading, setIsProfileLoading] = useState(false);

    const fetchProfile = useCallback(async () => {
      try {
        setIsProfileLoading(true);
        const membershipRef = subscription
          ? subscription?.sms_subscription_id
          : memberships
          ? memberships[membership_ref]
          : ''; // 1, 2, 3

        if (client_key && auth_token) {
            const res = await getUserProfile(client_key, auth_token, membershipRef);
            if (!res.success) {
              throw new Error('Unable to fetch Profile');
            }

            setProfile({ customer: res.customer, subscription: res.subscription });
            if (!getSubscriptionKey() && res.subscription) {
              setSubscriptionKey(res.subscription);
            }
        }
        setIsProfileLoading(false);
   
      } catch (err) {
        setIsProfileLoading(false);
        navigate(PATH_MEMBERSHIP.getMembershipError(), {
          state: {
            title: err?.error_message || 'Something went wrong when fetching user details',
            retryUrl: `${pathname}`,
          },
        });
      }
    }, [auth_token, membership_ref, memberships]);

    useEffect(() => {
        fetchProfile()
    }, [auth_token, membership_ref, memberships])

    return {
        isProfileLoading
    }
}