import React from 'react';
import PropTypes from 'prop-types';

import './Button.styles.scss';
import Arrow from 'components/Svgs/Arrow';
import Throphy from 'components/Svgs/Throphy';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

import { COLOURS } from 'constants/colours';

const Button = ({
  variant = 'standard',
  onClick,
  colour = 'white',
  arrow = false,
  throphy = false,
  text,
  overrideStyles,
  fullWidth,
  btnType = 'button',
  disabled,
  isLoading,
}) => {
  const classSelector = `${variant}_${colour}`;
  const variants = {
    standard_social: 'bg-social border-social text-black hover:bg-gray hover:border-gray',
    standard_club: 'bg-club border-club text-black hover:bg-gray hover:border-gray',
    standard_elite: 'bg-elite border-elite text-black hover:bg-gray hover:border-gray',
    outline_white: `bg-transparent border-white text-white hover:text-${colour} hover:border-${colour}`,
    outline_social: 'bg-transparent border-social text-social hover:border-gray hover:text-gray',
    outline_club: 'bg-transparent border-club text-club hover:opacity-60',
    outline_elite: 'bg-transparent border-elite text-elite hover:opacity-60',
  };

  const iconColour = {
    standard: COLOURS.black,
    outline: COLOURS[colour],
  };

  return (
    <button
      data-testid="Button"
      className={`${
        variants[classSelector]
      } border-2 px-3 uppercase font-bold flex items-center gap-2 justify-center transition-all duration-200 ${overrideStyles} ${
        fullWidth ? 'w-full' : ''
      } ${disabled || isLoading ? 'bg-gray border-gray text-black ' : ''}`}
      onClick={onClick}
      type={btnType}
      disabled={disabled || isLoading}
    >
      {text}
      {!isLoading && (
        <>
          {arrow && <Arrow colour={iconColour[variant]} />}
          {throphy && <Throphy colour={iconColour[variant]} />}
        </>
      )}
      {isLoading && <LoadingSpinner size={15} colour={iconColour[variant]} />}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined']),
  colour: PropTypes.oneOf(['social', 'club', 'elite', 'white']),
  onClick: PropTypes.func,
  arrow: PropTypes.bool,
  throphy: PropTypes.bool,
  text: PropTypes.string.isRequired,
  overrideStyles: PropTypes.string,
  fullWidth: PropTypes.bool,
  btnType: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default Button;
