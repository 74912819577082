import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import './Error.styles.scss';
import ErrorImage from 'components/ErrorImage/ErrorImage';
import Chevrons from 'components/Chevrons/Chevrons';
import BodyText from 'components/BodyText/BodyText';
import Button from 'components/Button/Button';
import { useLocation, useNavigate } from 'react-router';

const Error = ({ btnText = 'Try again', center }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state || {};
  const { title = '', retryUrl = '/verify/no-token' } = state;

  const onClick = useCallback(() => {
    navigate(retryUrl);
  }, []);

  return (
    <div data-testid="Error" className={`flex flex-col relative h-full text-center ${center ? 'justify-center' : ''}`}>
      <div className="left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] hidden md:block fixed">
        <Chevrons />
      </div>
      <div className="w-[300px] min-h-[200px] mx-auto">
        <div>
          <ErrorImage />
        </div>
        <div className="mb-4 mt-6">
          <div className="mb-4">
            <BodyText text="Oh no!" colour="elite" size="4xl" weight="bold" uppercase />
          </div>
          <BodyText text={title} colour="elite" tracking="2.17px" size="2xl" weight="book" uppercase />
        </div>
        <div>
          <BodyText
            text="Please go back and try again. If you continue to have problems, please contact us on 01252 732300"
            colour="white"
            weight="book"
            tracking="0.55px"
            size="22px"
            uppercase
          />
        </div>
        <div className="flex justify-center mt-6">
          <Button colour="elite" text={btnText} onClick={onClick} arrow />
        </div>
      </div>
    </div>
  );
};

Error.propTypes = {
  title: PropTypes.string,
  helperText: PropTypes.string,
  btnText: PropTypes.string,
  retryUrl: PropTypes.string,
  center: PropTypes.bool,
};

export default Error;
