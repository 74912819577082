import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import StripeForm from './StripeForm';

import { COLOURS } from 'constants/colours';

import { useAuthContext } from 'hooks/useAuthContext';

import './StripeNewCard.styles.scss';

import { postCheckout, postPaymentMethod } from 'api';

const appearance = {
  theme: 'stripe',
  variables: {
    fontFamily: 'Bebas Neue',
    borderRadius: '6px',
    colorIconTabSelected: COLOURS.black,
  },
  rules: {
    '.Tab--selected': {
      color: COLOURS.black,
      boxShadow: 'none',
    },
    '.Tab:active': {
      boxShadow: 'none',
      outline: 'none',
    },
    '.Tab--selected:hover': {
      color: COLOURS.black,
    },
    '.Tab--selected:focus': {
      boxShadow: 'none',
      outline: 'none',
    },
    '.Icon.p-TabIcon--selected': {
      fill: COLOURS.black,
    },
    '.Input': {
      border: `1px solid ${COLOURS.gray}`,
    },
    '.Input:focus': {
      outline: 'none',
      boxShadow: 'none',
      borderColor: COLOURS.black,
    },
  },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_PUBLISHABLE);

// - client_key
// - subscription_type - 'social', 'club' or 'elite'
// - payment_method_id - only needed if user has existing payment method
const StripeNewCard = ({ variant = 'social', stripeSetup = 'pi', redirectTo }) => {
  const [clientSecret, setClientSecret] = useState('');

  const { auth_token, payment_methods } = useAuthContext();
  const { new_membership_type, action, venue } = useParams();

  const paymentMethodId = payment_methods[0]?.payment_method_id || '';

  const fetchClientSecret = useCallback(async () => {
    try {
      const data = { client_key: venue, subscription_type: new_membership_type, payment_method_id: paymentMethodId };
      const res = stripeSetup === 'se' ? await postPaymentMethod(auth_token) : await postCheckout(auth_token, data);
      setClientSecret(res.client_secret);
    } catch (err) {
      console.log('🚀 ~ file: PaymentMethods.jsx:24 ~ fetchClientSecret ~ err:', err);
    }
  }, [auth_token]);

  useEffect(() => {
    if (auth_token) {
      fetchClientSecret();
    }
  }, [auth_token]);

  const options = {
    // passing the client secret obtained from the server
    // clientSecret:
    //   stripeSetup === 'pi'
    //     ? 'pi_3NM7ypK8yp1HDadn0Kn3aPFW_secret_YEh9I7aVJSziQPitFgrtIPkCy'
    //     : 'seti_1NNFrpK8yp1HDadn7xRLbHvM_secret_O9YzUvjlHcee0nnSiGFNBhSlSvEH5Mk',
    clientSecret,

    fonts: [
      { cssSrc: 'https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@400;500;600&display=swap' },
    ],

    appearance,
  };

  return clientSecret ? (
    <Elements stripe={stripePromise} options={options}>
      <StripeForm variant={variant} stripeSetup={stripeSetup} redirectTo={redirectTo} />
    </Elements>
  ) : (
    <></>
  );
};

StripeNewCard.propTypes = {
  variant: PropTypes.oneOf(['social', 'club', 'elite']),
  stripeSetup: PropTypes.oneOf(['pi', 'se']),
  redirectTo: PropTypes.string,
  price: PropTypes.string,
};

export default StripeNewCard;
