const STORAGE_KEYS = {
  CUSTOMER: 'CUSTOMER',
  SUBSCRIPTION: 'SUBSCRIPTION',
  AUTH_TOKEN: 'AUTH_TOKEN',
  CLIENT_KEY: 'CLIENT_KEY',
  MEMBERSHIP_REF: 'MEMBERSHIP_REF',
  SELECTED_LOCATION: 'SELECTED_LOCATION'
};

// Setter function for customer key
export function setCustomerKey(customer) {
  try {
    const customerJSON = JSON.stringify(customer);

    // Store the JSON string in local storage
    localStorage.setItem(STORAGE_KEYS.CUSTOMER, customerJSON);
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ setCustomerKey ~ err:', err);
  }
}

// Setter function for subscription key
export function setSubscriptionKey(subscription) {
  try {
    if (!subscription) {
      return;
    }

    const subscriptionJSON = JSON.stringify(subscription);
    // Store the JSON string in local storage
    localStorage.setItem(STORAGE_KEYS.SUBSCRIPTION, subscriptionJSON);
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ setSubscriptionKey ~ err:', err);
  }
}

// Setter function for auth_token key
export function setAuthToken(auth_token) {
  try {
    // Store the JSON string in local storage
    localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, auth_token);
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ setAuthToken ~ err:', err);
  }
}

export function setClientKey(client_key) {
  try {
    // Store the JSON string in local storage
    localStorage.setItem(STORAGE_KEYS.CLIENT_KEY, client_key);
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ setAuthToken ~ err:', err);
  }
}

export function setMembershipRefKey(membership_ref) {
  try {
    // Store the JSON string in local storage
    if (membership_ref) {
      localStorage.setItem(STORAGE_KEYS.MEMBERSHIP_REF, membership_ref);
    }
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ setAuthToken ~ err:', err);
  }
}

export function getCustomerKey() {
  try {
    // Get the JSON string in local storage
    const customer = localStorage.getItem(STORAGE_KEYS.CUSTOMER);
    return JSON.parse(customer);
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ getCustomerKey ~ err:', err);
  }
}

export function getSubscriptionKey() {
  try {
    // Get the JSON string in local storage
    const subscription = localStorage.getItem(STORAGE_KEYS.SUBSCRIPTION);
    return JSON.parse(subscription);
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ getSubscriptionKey ~ err:', err);
  }
}

export function getAuthTokenKey() {
  try {
    // Get the JSON string in local storage
    const auth_token = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
    return auth_token;
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ getAuthTokenKey ~ err:', err);
  }
}

export function getClientKey() {
  try {
    // Get the JSON string in local storage
    const client_key = localStorage.getItem(STORAGE_KEYS.CLIENT_KEY);
    return client_key;
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ getClientKey ~ err:', err);
  }
}

export function getMembershipRefKey() {
  try {
    // Get the JSON string in local storage
    const membership_ref = localStorage.getItem(STORAGE_KEYS.MEMBERSHIP_REF);
    return membership_ref;
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ getMembershipRefKey ~ err:', err);
  }
}


export function setSelectedLocatioKey(value) {
  try {
    // Get the JSON string in local storage
    const client_key = localStorage.setItem(STORAGE_KEYS.SELECTED_LOCATION, value);
    return client_key;
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ getClientKey ~ err:', err);
  }
}


export function getSelectedLocationKey() {
  try {
    // Get the JSON string in local storage
    const client_key = localStorage.getItem(STORAGE_KEYS.SELECTED_LOCATION);
    return client_key;
  } catch (err) {
    console.log('🚀 ~ file: localStorage.js:13 ~ getClientKey ~ err:', err);
  }
}

export function setLocalStorageKeys(data) {
  const { customer, subscription, auth_token, client_key, membership_ref } = data;
  setCustomerKey(customer);
  setSubscriptionKey(subscription);
  setAuthToken(auth_token);
  setClientKey(client_key);
  setMembershipRefKey(membership_ref);
}

export function getLocalStorageKeys() {
  const customer = getCustomerKey();
  const subscription = getSubscriptionKey();
  const auth_token = getAuthTokenKey();
  const client_key = getClientKey();
  const membership_ref = getMembershipRefKey();


  return {
    customer,
    subscription,
    auth_token,
    client_key,
    membership_ref,
  };
}

export function clearLocalStorageKeys() {
  localStorage.clear();
}
