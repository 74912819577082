import { VERIFY_DATA } from 'mock/verify';
import PropTypes from 'prop-types';

import { createContext, useReducer, useCallback, useMemo, useEffect } from 'react';
import { setSession } from 'utils/axios';
import { getLocalStorageKeys, setLocalStorageKeys, setSelectedLocatioKey } from 'utils/localStorage';

// ACTIONS ----------------------------------------------------------------------

const ACTION_TYPES = {
  VERIFY: 'VERIFY',
  VALIDATE_USER: 'VALIDATE_USER',
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_USER_SELECTED_LOCATION: 'SET_USER_SELECTED_LOCATION',
  SET_PROFILE: 'SET_PROFILE',
  SET_MEMBERSHIPS: 'SET_MEMBERSHIPS',
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
  SET_MEMBERSHIP_REF: 'SET_MEMBERSHIP_REF',
  LOGOUT: 'LOGOUT',
};

// REDUCER ----------------------------------------------------------------------

const initialState = {
  url_token: '',
  verification_token: '',
  verification_key: '',
  client_key: '',
  membership_ref: '',

  is_authenticated: false,

  customer: null,
  subscription: null,

  auth_token: '',
  locations: [],
  userSelectedLocation: '',

  memberships: null,

  payment_methods: [],
};

const reducer = (state, action) => {
  if (!action.type) throw new Error('auth reducer action must have a type');
  switch (action.type) {
    case ACTION_TYPES.VERIFY:
      return {
        ...state,
        customer: action.payload.customer,
        subscription: action.payload.subscription,
        auth_token: action.payload.auth_token,
        userSelectedLocation: action.payload.subscription?.sms_location,
        client_key: action.payload?.client_key,
        membership_ref: action.payload?.membership_ref,
      };
    case ACTION_TYPES.SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload.locations,
      };
    case ACTION_TYPES.SET_USER_SELECTED_LOCATION:
      return {
        ...state,
        userSelectedLocation: action.payload,
      };
    case ACTION_TYPES.SET_PROFILE:
      return {
        ...state,
        customer: action.payload.customer,
        subscription: action.payload.subscription,
      };
    case ACTION_TYPES.SET_MEMBERSHIPS:
      return {
        ...state,
        memberships: action.payload.memberships,
      };
    case ACTION_TYPES.SET_MEMBERSHIP_REF:
      return {
        ...state,
        membership_ref: action.payload.membership_ref,
      };
    case ACTION_TYPES.SET_PAYMENT_METHODS:
      return {
        ...state,
        payment_methods: action.payload.payment_methods,
      };
    case ACTION_TYPES.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

// CONTEXT ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// PROVIDER ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { customer, auth_token } = state;
  // store url token in state and get verification token using URL token on /verify/{url_token} render
  const getVerificationToken = useCallback(async (url_token) => {}, []);

  // const setVerificationKey = useCallback(async (verification_key) => {
  //   dispatch({
  //     action: ACTION_TYPES.VALIDATE_USER,
  //     payload: { verification_key },
  //   });
  // }, []);

  // trigger code resend using veification token and url token from state
  const resendCode = useCallback(async () => {}, []);

  // verify user sending verification_pin, verification_token and url_token, gets back the auth_token and user data
  const setSessionAndUserData = useCallback(async (data, setToLocalStorage) => {
    dispatch({
      type: ACTION_TYPES.VERIFY,
      payload: data,
    });
    if (setToLocalStorage) {
      setLocalStorageKeys(data);
    }
  }, []);

  useEffect(() => {
    const {
      customer: local_storage_customer,
      subscription: local_storage_subscription,
      auth_token: local_storage_auth_token,
      client_key: local_storage_client_key,
      membership_ref: local_storage_membership_ref,
    } = getLocalStorageKeys();

    if (!customer && !auth_token && local_storage_customer && local_storage_auth_token && local_storage_client_key) {
      setSessionAndUserData(
        {
          customer: local_storage_customer,
          subscription: local_storage_subscription,
          auth_token: local_storage_auth_token,
          client_key: local_storage_client_key,
          membership_ref: local_storage_membership_ref || '',
        },
        false
      );
    }
  }, []);

  const setLocations = useCallback(async (data) => {
    dispatch({
      type: ACTION_TYPES.SET_LOCATIONS,
      payload: data,
    });
  }, []);

  const setUserSelectedLocation = useCallback(
    async (data) => {
      dispatch({
        type: ACTION_TYPES.SET_USER_SELECTED_LOCATION,
        payload: data,
      });
      if (state.locations) {
        const customerLocation = state.locations.find((l) => l?.client_key === data)?.name;
        setSelectedLocatioKey(customerLocation);
      }
    },
    [state.locations]
  );

  const setProfile = useCallback(async (data) => {
    dispatch({
      type: ACTION_TYPES.SET_PROFILE,
      payload: data,
    });
  }, []);

  const setMemberships = useCallback(async (memberships) => {
    dispatch({
      type: ACTION_TYPES.SET_MEMBERSHIPS,
      payload: { memberships },
    });
  }, []);

  const setMembershipRef = useCallback(async (membership_ref) => {
    dispatch({
      type: ACTION_TYPES.SET_MEMBERSHIP_REF,
      payload: { membership_ref },
    });
  }, []);

  const setPaymentMethods = useCallback(async (payment_methods) => {
    dispatch({
      type: ACTION_TYPES.SET_PAYMENT_METHODS,
      payload: { payment_methods },
    });
  }, []);

  const values = useMemo(() => {
    return {
      ...state,
      resendCode,
      setLocations,
      setUserSelectedLocation,
      setSessionAndUserData,
      setProfile,
      setMemberships,
      setPaymentMethods,
      setMembershipRef,
    };
  }, [state, getVerificationToken, resendCode, setSessionAndUserData]);

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
