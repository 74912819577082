import axios from 'axios';
import { BASE_URL } from 'config/config';
import { clearLocalStorageKeys, getClientKey, getCustomerKey } from './localStorage';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      const client_key = getClientKey();
      const customer = getCustomerKey();
      const { sms_login_id } = customer;
      clearLocalStorageKeys();
      window.location.href = `${window.location.origin}/verify/${client_key}/${sms_login_id}`;
      return;
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
