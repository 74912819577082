import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { MEMBERSHIPS_MAP } from 'constants/membership';
import { useAuthContext } from 'hooks/useAuthContext';
import { getLocalStorageKeys } from 'utils/localStorage';

const AuthGuard = ({ children }) => {
  const { setSessionAndUserData } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    // can use this to verify auth
    const { customer, subscription, auth_token, client_key } = getLocalStorageKeys();
    if (!auth_token || !client_key) {
      navigate('/verify/no-token');
    }

    if (customer && auth_token) {
      const membership_ref = customer.sms_subscription_id;
      setSessionAndUserData(
        { customer, subscription, auth_token, client_key, membership_ref: MEMBERSHIPS_MAP[membership_ref] },
        false
      );
    }
  }, []);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
