import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import SingleColumnWrapper from 'components/SingleColumnWrapper/SingleColumnWrapper';
import BodyText from 'components/BodyText/BodyText';
import Logo from 'components/Logo/Logo';
import CodeInputGroup from 'components/CodeInputGroup/CodeInputGroup';
import Button from 'components/Button/Button';
import Divider from 'components/Divider/Divider';
import Link from 'components/Link/Link';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

import { useAuthContext } from 'hooks/useAuthContext';
import { PATH_MEMBERSHIP, PATH_VERIFY } from 'routes/paths';
import { postTokenRefresh, postTokenVerify, postValidateUser } from 'api';
import { clearLocalStorageKeys } from 'utils/localStorage';
import { MEMBERSHIPS_MAP } from 'constants/membership';

const Verify = () => {
  const { client_key, verification_token, membership_ref = '' } = useParams();

  const { setSessionAndUserData } = useAuthContext();

  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [key, setKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);

  useEffect(() => {
    const validateUser = async () => {
      try {
        setIsLoading(true);
        const data = {
          sms_login_id: verification_token,
          client_key,
          membership_ref,
        };
        const resData = await postValidateUser(data);
        setKey(resData?.key);
      } catch (err) {
        // navigate to error?
        navigate(PATH_VERIFY.getVerifyErrorRoute(client_key, verification_token), {
          state: {
            client_key,
            verification_token,
            title: err?.error_message || 'Something went wrong when validating user',
            retryUrl: PATH_VERIFY.getVerifyRootRoute(client_key, verification_token),
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    clearLocalStorageKeys();

    if (verification_token !== 'no-token') {
      validateUser();
    }
  }, []);

  const verifyCode = useCallback(async () => {
    try {
      setIsVerifyLoading(true);
      const data = await postTokenVerify({
        sms_login_id: verification_token,
        client_key,
        key: key,
        token: code,
        membership_ref,
      });
      // this will set to context and to localStorage
      setSessionAndUserData({ ...data, client_key, membership_ref: MEMBERSHIPS_MAP[membership_ref] }, true);

      const { subscription } = data;
      navigate(subscription ? `/${PATH_MEMBERSHIP.root}` : PATH_MEMBERSHIP.chooseMembership);
    } catch (err) {
      navigate(PATH_VERIFY.getVerifyErrorRoute(client_key, verification_token), {
        state: {
          client_key,
          verification_token,
          title: err?.error_message || 'Something went wrong when verifying',
          retryUrl: PATH_VERIFY.getVerifyRootRoute(client_key, verification_token),
        },
      });
    } finally {
      setIsVerifyLoading(false);
    }
  }, [verification_token, client_key, key, code]);

  const handleRefreshCode = useCallback(async () => {
    try {
      await postTokenRefresh({ key });
    } catch (err) {
      navigate(PATH_VERIFY.getVerifyErrorRoute(client_key, verification_token), {
        state: {
          client_key,
          verification_token,
          title: err?.error_message || 'Something went wrong when refreshing code',
          retryUrl: PATH_VERIFY.getVerifyRootRoute(client_key, verification_token),
        },
      });
    }
  }, [key]);

  if (isLoading) {
    return (
      <SingleColumnWrapper>
        <LoadingSpinner />
      </SingleColumnWrapper>
    );
  }

  return (
    <SingleColumnWrapper styleOverrides="md:justify-center">
      <div className="w-full md:mb-10">
        <BodyText text="Welcome to" tracking="2.17px" size="2xl" weight="book" uppercase />
        <div className="w-[300px] md:w-[460px] mx-auto">
          <Logo />
        </div>
      </div>
      <div className="w-full flex-1 flex flex-col justify-center md:block md:flex-grow-0">
        <BodyText
          text="PLEASE ENTER YOUR VERIFICATION CODE…"
          size="2xl"
          tracking="2.17px"
          weight="bold"
          colour="white"
        />
        <div className="my-6">
          <BodyText text="We’ve sent you an email with your 6 digit code" weight="book" colour="gray" size="xl" />
        </div>
        <div>
          <CodeInputGroup onChange={setCode} value={code} />
        </div>
        <div className="flex justify-center my-10">
          <Button
            variant="standard"
            colour="elite"
            text="Verify now"
            arrow
            onClick={verifyCode}
            disabled={code?.length < 6}
            isLoading={isVerifyLoading}
          />
        </div>
        <Divider />
        <div className="my-4 flex gap-2 justify-center">
          <BodyText text="Not received your verification code?" weight="book" colour="white" tracking="0.45px" />
          <Link text="RESEND CODE" underline bold onClick={handleRefreshCode} />
        </div>
      </div>
    </SingleColumnWrapper>
  );
};

Verify.propTypes = {};

export default Verify;
