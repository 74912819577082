import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import MembershipHeader from 'components/MembershipHeader/MembershipHeader';
import BackButton from 'components/BackButton/BackButton';

import { PATH_MEMBERSHIP } from 'routes/paths';

import { useAuthContext } from 'hooks/useAuthContext';

import { getMemberships, getPaymentMethods, getUserProfile } from 'api';

const INDEX_ROUTES = ['/membership/choose-membership', '/membership'];
const CLOSE_BUTTON_ROUTES = ['/pay/success', '/payment-methods'];

const MembershipLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname;

  const showBackButton = !INDEX_ROUTES.includes(path);
  const showCloseButton = CLOSE_BUTTON_ROUTES.find((route) => {
    const match = path.match(route);
    return (match && path === match[0]) || path.match('/pay/success');
  });
  const { auth_token, setPaymentMethods, setMemberships, memberships } = useAuthContext();

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const res = await getPaymentMethods(auth_token);
      if (!res.success) {
        throw new Error('Unable to fetch payment methods');
      }

      if (res.payment_methods) {
        setPaymentMethods(res.payment_methods);
      }
    } catch (err) {
      navigate(PATH_MEMBERSHIP.getMembershipError(), {
        state: {
          title: 'Something went wrong when fetching payment methods',
          retryUrl: `${path}`,
        },
      });
    }
  }, [auth_token]);

  useEffect(() => {
    if (auth_token) {
      fetchPaymentMethods();
    }
  }, [auth_token]);

  const fetchMemberships = useCallback(async () => {
    try {
      if (!memberships) {
        const res = await getMemberships(auth_token);
        if (!res.success) {
          throw new Error('Unable to fetch memberships');
        }
        setMemberships(res?.memberships);
      }
    } catch (err) {
      navigate(PATH_MEMBERSHIP.getMembershipError(), {
        state: {
          title: 'Something went wrong when fetching memberships',
          retryUrl: `${path}`,
        },
      });
    }
  }, [auth_token]);

  useEffect(() => {
    if (auth_token) {
      fetchMemberships();
    }
  }, [auth_token]);

  return (
    <div className="p-8 pt-20 bg-black min-h-screen">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="h-full w-full max-w-[1100px] mx-auto"
      >
        <div className="w-full mb-10  relative">
          {showBackButton && (
            <div className="absolute left-0 top-[-30px] md:top-[30px]">
              <BackButton
                closeUrl={showCloseButton ? `/${PATH_MEMBERSHIP.root}` : null}
                variant={showCloseButton ? 'close' : 'back'}
              />
            </div>
          )}
          <MembershipHeader />
        </div>

        <Outlet />
      </motion.div>
    </div>
  );
};

MembershipLayout.propTypes = {};

export default MembershipLayout;
