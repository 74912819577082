import { useNavigate } from 'react-router';
import { useAuthContext } from './useAuthContext';
import { PATH_MEMBERSHIP } from 'routes/paths';
import { useEffect } from 'react';

import { getSubscriptionKey } from 'utils/localStorage';

export const usePaymentRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const subscription = getSubscriptionKey();
    if (!subscription) {
      navigate(PATH_MEMBERSHIP.chooseMembership);
    }
  }, []);
};
