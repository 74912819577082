import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './StripeExistingCard.styles.scss';

import BodyText from 'components/BodyText/BodyText';
import Button from 'components/Button/Button';
import MembershipLogoWithText from 'components/MembershipLogoWithText/MembershipLogoWithText';
import { useAuthContext } from 'hooks/useAuthContext';
import { useParams } from 'react-router';
import { postCheckout } from 'api';

const StripeExistingCard = ({ variant, card, price = '£7.99', onChangePaymentType, onPayNow, isPayNowLoading }) => {
  const [clientSecret, setClientSecret] = useState('');

  const { auth_token, payment_methods } = useAuthContext();
  const { new_membership_type, action, venue } = useParams();

  const paymentMethodId = payment_methods[0]?.payment_method_id || '';

  const fetchClientSecret = useCallback(async () => {
    try {
      const data = { client_key: venue, subscription_type: new_membership_type, payment_method_id: paymentMethodId };
      const res = await postCheckout(auth_token, data);
      setClientSecret(res.client_secret);
    } catch (err) {
      console.log('🚀 ~ file: PaymentMethods.jsx:24 ~ fetchClientSecret ~ err:', err);
    }
  }, [auth_token]);

  useEffect(() => {
    if (auth_token) {
      fetchClientSecret();
    }
  }, [auth_token]);

  const text = `Renew your ${variant} membership for 12 months`;
  const cardText = `Using card ${card?.number}`;
  return (
    <div data-testid="StripeExistingCard" className="text-center mt-2 md:mt-10">
      <div>
        <BodyText size="4xl" text={text} colour={variant} weight="bold" tracking="1.98px" uppercase />
      </div>
      <div className="my-6">
        <BodyText size="3xl" text={cardText} colour={variant} weight="bold" tracking="1.17px" uppercase />
      </div>
      <div className="block lg:hidden">
        <MembershipLogoWithText variant={variant} price={price} />
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-4 justify-center w-[180px] mt-4 lg:mt-0 lg:w-full mx-auto">
        <Button
          variant="outline"
          colour={variant}
          text="Change payment type"
          onClick={onChangePaymentType}
          arrow
          overrideStyles={'min-w-[150px]'}
        />
        <Button
          variant="standard"
          colour={variant}
          text="Pay now"
          onClick={onPayNow}
          overrideStyles={'min-w-[150px]'}
          isLoading={isPayNowLoading}
        />
      </div>
    </div>
  );
};

StripeExistingCard.propTypes = {
  variant: PropTypes.oneOf(['social', 'club', 'elite']),
  card: PropTypes.string,
  price: PropTypes.string,
  onChangePaymentType: PropTypes.func,
  onPayNow: PropTypes.func,
  isPayNowLoading: PropTypes.bool,
};

export default StripeExistingCard;
