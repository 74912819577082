import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './MembershipLogo.styles.scss';

import SocialSVG from 'assets/social-grid.svg';
import ClubSVG from 'assets/club-grid.svg';
import EliteSVG from 'assets/elite-grid.svg';

const MembershipLogo = ({ variant }) => {
  const getSVG = useMemo(() => {
    switch (variant) {
      case 'social': {
        return SocialSVG;
      }
      case 'club': {
        return ClubSVG;
      }
      case 'elite': {
        return EliteSVG;
      }
      default:
        return SocialSVG;
    }
  }, [variant]);
  return <img data-testid="MembershipLogo" src={getSVG} alt="membership-logo" className="w-full h-full" />;
};

MembershipLogo.propTypes = {
  variant: PropTypes.oneOf(['social', 'club', 'elite']),
};

export default MembershipLogo;
