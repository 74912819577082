import React from 'react';
import PropTypes from 'prop-types';
import { COLOURS } from '../../constants/colours';

const Throphy = ({ colour = COLOURS.black }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.846" height="17.365" viewBox="0 0 19.846 17.365">
      <path
        id="Icon_metro-trophy"
        data-name="Icon metro-trophy"
        d="M18.7,6.337V3.856H6.292V6.337H2.571V8.818a3.724,3.724,0,0,0,4.838,3.551A6.205,6.205,0,0,0,11.253,14.9v3.845h-1.24a2.481,2.481,0,0,0-2.481,2.481h9.923a2.481,2.481,0,0,0-2.481-2.481h-1.24V14.9a6.2,6.2,0,0,0,3.845-2.527,3.724,3.724,0,0,0,4.838-3.551V6.337Zm-12.4,4.729A2.251,2.251,0,0,1,4.044,8.818V7.577H6.292v1.24A6.187,6.187,0,0,0,6.7,11.028,2.251,2.251,0,0,1,6.292,11.066ZM20.944,8.818a2.246,2.246,0,0,1-2.654,2.211A6.187,6.187,0,0,0,18.7,8.818V7.577h2.248Z"
        transform="translate(-2.571 -3.856)"
        fill={colour}
      />
    </svg>
  );
};

Throphy.propTypes = {
  colour: PropTypes.string,
};

export default Throphy;
