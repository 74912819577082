import React from 'react';
import PropTypes from 'prop-types';
import './SingleColumnWrapper.styles.scss';

const SingleColumnWrapper = ({ children, styleOverrides = '' }) => {
  return (
    <div
      data-testid="SingleColumnWrapper"
      className={`mx-auto max-w-[780px] h-full flex flex-col md:items-center text-center ${styleOverrides}`}
    >
      {children}
    </div>
  );
};

SingleColumnWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  styleOverrides: PropTypes.string,
};

export default SingleColumnWrapper;
