import { useNavigate } from 'react-router';
import { useAuthContext } from './useAuthContext';
import { PATH_MEMBERSHIP } from 'routes/paths';
import { useEffect } from 'react';
import { useComponentDidUpdate } from './useComponentDidUpdate';

export const useHasSubscriptionRedirect = () => {
  const { subscription } = useAuthContext();
  const navigate = useNavigate();

  useComponentDidUpdate({
    callback: () => {
      if (subscription) {
        const { sms_active, stripe_active } = subscription;
        if (sms_active && stripe_active) {
          navigate(`/${PATH_MEMBERSHIP.root}/`);
        }
      }
    },
    deps: [subscription],
  });
};
