import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';

import Divider from 'components/Divider/Divider';
import BodyText from 'components/BodyText/BodyText';
import MembershipLogoWithText from 'components/MembershipLogoWithText/MembershipLogoWithText';
import Button from 'components/Button/Button';
import SingleColumnWrapper from 'components/SingleColumnWrapper/SingleColumnWrapper';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

import { MEMBERSHIP_CARD_DETAILS } from 'constants/membership';
import { PAYMENT_ACTIONS } from 'constants/payments';

import { PATH_MEMBERSHIP, PATH_PAYMENT_METHODS } from 'routes/paths';

import { useComponentDidUpdate } from 'hooks/useComponentDidUpdate';
import { useLoadProfile } from 'hooks/useLoadProfile';
import { getLocalStorageKeys } from 'utils/localStorage';

const MyMembership = (props) => {
  const { subscription } = getLocalStorageKeys();
  const navigate = useNavigate();

  const { subscription_type, stripe_active, stripe_end_date, sms_active, sms_end_date } = subscription || {};

  const price = MEMBERSHIP_CARD_DETAILS.find((c) => c?.variant === subscription_type)?.price;
  const expireDate = sms_end_date;

  const { isProfileLoading } = useLoadProfile();

  const handleRenew = useCallback(() => {
    navigate(`${PATH_MEMBERSHIP.getChooseVenueRoute(subscription_type, PAYMENT_ACTIONS.renew)}`);
  }, [subscription_type]);

  const handleChangePaymentType = useCallback(() => {
    navigate(`/${PATH_PAYMENT_METHODS.root}`);
  }, []);

  useComponentDidUpdate({
    callback: () => {
      if (!subscription) {
        navigate(PATH_MEMBERSHIP.chooseMembership);
      }
    },
    deps: [subscription],
  });

  const text = stripe_active
    ? `Your subscription will auto renew on ${stripe_end_date}`
    : `To continue with this membership simply autorenew for another year, for just ${price} annually.`;

  if (isProfileLoading) {
    return (
      <SingleColumnWrapper>
        <LoadingSpinner />
      </SingleColumnWrapper>
    );
  }

  return (
    <div className="text-center max-w-[580px] mx-auto">
      <Divider />
      <div>
        <div className="mt-2">
          <BodyText
            text="YOU CURRENTLY HAVE THE FOLLOWING MEMBERSHIP…"
            colour="white"
            weight="book"
            size="2xl"
            tracking="2.17px"
          />
        </div>
        <div className="flex justify-center my-4 w-[200px] h-[250px] mx-auto">
          <MembershipLogoWithText variant={subscription_type} text={`Expires ${expireDate}`} />
        </div>
        <div className="max-w-[374px] mx-auto text-center">
          <BodyText colour="white" text={text} weight="book" size="xl" tracking="0.55px" />
        </div>
        <div className="flex flex-col md:flex-row gap-4 md:gap-6 justify-center my-6 mx-auto w-4/6">
          {!stripe_active && sms_active && (
            <Button
              variant="standard"
              colour={subscription_type}
              text={`Renew ${subscription_type} membership`}
              arrow
              onClick={handleRenew}
            />
          )}
          <Button
            variant="outline"
            colour="club"
            text={`Change payment type`}
            arrow
            onClick={handleChangePaymentType}
          />
        </div>
      </div>
    </div>
  );
};

MyMembership.propTypes = {};

export default MyMembership;
