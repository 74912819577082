import React from 'react';
import PropTypes from 'prop-types';
import './Divider.styles.scss';

const Divider = (props) => {
  return <div data-testid="Divider" className="h-[1px] w-full bg-gray"></div>;
};

Divider.propTypes = {};

export default Divider;
