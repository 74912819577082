import React from 'react';
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';

const TestLayout = () => {
  return (
    <div className="p-8 bg-black h-screen">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="h-full"
      >
        <Outlet context={{ test: 'test value passed through outlet ctx!' }} />
      </motion.div>
    </div>
  );
};

TestLayout.propTypes = {};

export default TestLayout;
