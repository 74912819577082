import Router from './routes/routes';
import Root from './components/Root/Root';
import { AuthProvider } from './context/auth/AuthContext';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Root>
          <Router />
        </Root>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
