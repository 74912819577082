import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import SingleColumnWrapper from 'components/SingleColumnWrapper/SingleColumnWrapper';
import BodyText from 'components/BodyText/BodyText';
import Divider from 'components/Divider/Divider';
import PaymentMethodGroup from 'components/PaymentMethodGroup/PaymentMethodGroup';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

import { PATH_PAYMENT_METHODS } from 'routes/paths';

import { useAuthContext } from 'hooks/useAuthContext';
import { usePaymentRedirect } from 'hooks/usePaymentRedirect';

import { deletePaymentMethod, postPaymentMethod } from 'api';

const PaymentMethods = (props) => {
  const navigate = useNavigate();

  const { payment_methods, auth_token, setPaymentMethods, subscription } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);

  const onAddPayment = useCallback(async (data) => {
    navigate(PATH_PAYMENT_METHODS.add);
  }, []);

  const onRemovePayment = useCallback(
    async (payment_method_id, new_payment_method_id, subscription_type) => {
      try {
        const res = await deletePaymentMethod(auth_token, {
          payment_method_id,
          new_payment_method_id,
          subscription_type,
        });
        if (!res.success) {
          throw new Error('Unable to remove payment method');
        }
        setPaymentMethods(res.payment_methods);
      } catch (err) {
        navigate(PATH_PAYMENT_METHODS.error, {
          state: {
            title: err?.error_message || 'Something went wrong when fetching payment methods',
            retryUrl: `/${PATH_PAYMENT_METHODS.root}`,
          },
        });
      }
    },
    [auth_token]
  );

  const checkAndDeleteExtraCardPayment = useCallback(async () => {
    try {
      setIsLoading(true);
      if (payment_methods.length > 1) {
        const secondPaymentMethodId = payment_methods[1]?.payment_method_id;
        if (subscription) {
          // return  onRemovePayment(secondPaymentMethodId);
        }
        await onRemovePayment(secondPaymentMethodId);
      }
      setIsLoading(false);
    } catch (err) {
      console.log('🚀 ~ file: PaymentMethods.jsx:58 ~ deleteExtraCardPayment ~ err:', err);
    }
  }, [payment_methods]);

  useEffect(() => {
    checkAndDeleteExtraCardPayment();
  }, [checkAndDeleteExtraCardPayment]);

  usePaymentRedirect();

  if (isLoading) {
    return (
      <SingleColumnWrapper>
        <LoadingSpinner />
      </SingleColumnWrapper>
    );
  }

  return (
    <SingleColumnWrapper>
      <div className="mb-10 md:mb-20">
        <div className="my-2">
          <Divider />
        </div>
        <BodyText text="Update payment method" weight="book" uppercase size="2xl" />
      </div>
      <div>
        <PaymentMethodGroup onAddPayment={onAddPayment} onRemoveCard={onRemovePayment} cards={payment_methods} />
      </div>
    </SingleColumnWrapper>
  );
};

PaymentMethods.propTypes = {};

export default PaymentMethods;
