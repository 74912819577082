import React from 'react';
import PropTypes from 'prop-types';
import './ErrorImage.styles.scss';

import Warning from 'assets/warning.svg';

const ErrorImage = (props) => {
  return <img data-testid="ErrorImage" src={Warning} className="mx-auto" />;
};

ErrorImage.propTypes = {};

export default ErrorImage;
