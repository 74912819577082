import React from 'react';
import PropTypes from 'prop-types';

import AddPaymentMethod from 'components/AddPaymentMethod/AddPaymentMethod';
import PaymentCard from 'components/PaymentCard/PaymentCard';

import './PaymentMethodGroup.styles.scss';

const PaymentMethodGroup = ({ cards, onAddPayment, onRemoveCard }) => {
  return (
    <div data-testid="PaymentMethodGroup" className="flex gap-4 justify-center items-start flex-col md:flex-row">
      {cards.map(({ number, name, expiry, payment_method_id }, i) => (
        <PaymentCard
          number={number}
          name={name}
          expiry={expiry}
          onRemoveCard={onRemoveCard}
          payment_method_id={payment_method_id}
          key={`${expiry}_${i}`}
        />
      ))}
      <AddPaymentMethod onClick={onAddPayment} />
    </div>
  );
};

PaymentMethodGroup.propTypes = {
  cards: PropTypes.array,
  onAddPayment: PropTypes.func,
  onRemoveCard: PropTypes.func,
};

export default PaymentMethodGroup;
