import React from 'react';
import PropTypes from 'prop-types';

import TickSVG from 'components/Svgs/TickSVG';
import { COLOURS } from 'constants/colours';

import './CardListItem.styles.scss';

const CardListItem = ({ variant, text }) => {
  return (
    <li data-testid="CardListItem" className="flex leading-none mb-1 last:mb-0 text-left">
      <span className={`block relative text-center w-[16px] h-[16px] mr-2`}>
        <div className={`absolute top-[2px] left-0`}>
          <TickSVG colour={COLOURS[variant]} />
        </div>
        <p className={`relative top-[2px] text-xs font-bold mx-auto w-[16px]`}>✓</p>
      </span>
      <p className="text-white leading-5 text-xs">{text}</p>
    </li>
  );
};

CardListItem.propTypes = {
  variant: PropTypes.oneOf(['social', 'club', 'elite', 'white']),
  text: PropTypes.string,
};

export default CardListItem;
