import React from 'react';
import PropTypes from 'prop-types';
import './CardHeader.styles.scss';
import BodyText from 'components/BodyText/BodyText';
import MembershipLogo from 'components/MembershipLogo/MembershipLogo';
import Price from 'components/Price/Price';

const CardHeader = ({ variant, title, subtitle, price }) => {
  return (
    <div data-testid="CardHeader" className="flex gap-2 justify-between">
      <div className="flex flex-col text-left">
        <div>
          <BodyText text={title} size="4xl" colour={variant} weight="bold" uppercase />
          <BodyText text={subtitle} size="sm" colour="white" />
          <div className="mt-1 md:mt-2">
            <Price price={price} colour="white" />
          </div>
        </div>
      </div>
      <div className="w-[85px] md:w-[100px] relative top-[-7px]">
        <MembershipLogo variant={variant} />
      </div>
    </div>
  );
};

CardHeader.propTypes = {
  variant: PropTypes.oneOf(['social', 'club', 'elite', 'white']),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.string,
};

export default CardHeader;
