import React from 'react';

import RowWrapper from 'components/RowWrapper/RowWrapper';
import AddPaymentLeftContainer from 'components/AddPaymentLeftContainer/AddPaymentLeftContainer';
import StripeNewCard from 'components/StripeNewCard/StripeNewCard';

import { usePaymentRedirect } from 'hooks/usePaymentRedirect';

const PaymentMethodsAdd = (props) => {
  usePaymentRedirect();

  return (
    <RowWrapper>
      <AddPaymentLeftContainer />
      <div className="w-full md:w-3/6">
        <StripeNewCard stripeSetup="se" />
      </div>
    </RowWrapper>
  );
};

PaymentMethodsAdd.propTypes = {};

export default PaymentMethodsAdd;
