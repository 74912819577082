import React, { useEffect }  from 'react';
import { useLocation, useParams } from 'react-router';

import SingleColumnWrapper from 'components/SingleColumnWrapper/SingleColumnWrapper';
import BodyText from 'components/BodyText/BodyText';
import Divider from 'components/Divider/Divider';
import MembershipLogoWithText from 'components/MembershipLogoWithText/MembershipLogoWithText';
import Button from 'components/Button/Button';

import { PAYMENT_ACTIONS } from 'constants/payments';
import { useAuthContext } from 'hooks/useAuthContext';
import { getMembershipRefKey, getSelectedLocationKey } from 'utils/localStorage';

const MembershipPaymentSuccess = (props) => {
  const { pathname } = useLocation();
  const { new_membership_type, action, venue } = useParams();
  const { locations, setMembershipRef, membership_ref } = useAuthContext();
  const selectedLocationStored  = getSelectedLocationKey()
  const customerLocation = locations.find((l) => l?.client_key === venue)?.name || selectedLocationStored;  
  const isRenew = action === PAYMENT_ACTIONS.renew;
  const title = isRenew ? 'RENEWAL COMPLETED!' : 'CONGRATULATIONS';
  const subtitle = isRenew
    ? 'YOUR MEMBERSHIP HAS BEEN EXTENDED BY 12 MONTHS'
    : `YOU ARE NOW A ${new_membership_type} MEMBER OF ${customerLocation}`;
  const logoSubtext = 'What next...';
  const subscriptionHelperText = `YOU WILL RECEIVE A CONFIRMATION EMAIL SHORTLY CONTAINING ALL THE DETAILS OF ${
    isRenew ? 'renewed' : 'new'
  } YOUR  MEMBERSHIP`;

  useEffect(() => {
    const local_storage_membership_ref = getMembershipRefKey();
    if(local_storage_membership_ref && !membership_ref) {
      setMembershipRef(local_storage_membership_ref)
    }
  },[])

  return (
    <SingleColumnWrapper>
      <div className="md:min-w-[600px]">
        <BodyText text={title} colour={new_membership_type} size="5xl" weight="bold" tracking="2.28px" />
        <div className="my-2">
          <Divider />
        </div>
        <div>
          <BodyText text={subtitle} weight="book" colour="white" size="2xl" tracking="2.17px" uppercase />
        </div>
      </div>
      <div className="max-w-[500px]">
        <div className="my-8">
          <MembershipLogoWithText variant={new_membership_type} text={logoSubtext} />
        </div>
        <div className="my-4">
          <Divider />
        </div>
        <div className="text-center">
          <BodyText text={subscriptionHelperText} colour="white" weight="book" size="xl" tracking="1.08px" uppercase />
        </div>
        <div className="flex flex-col my-6 gap-2 max-w-[200px] mx-auto items-center">
          <BodyText text="Take part in a weekly member event" weight="book" />
          <Button text="VIEW MEMBER EVENTS" throphy colour={new_membership_type} />
        </div>
      </div>
    </SingleColumnWrapper>
  );
};

MembershipPaymentSuccess.propTypes = {};

export default MembershipPaymentSuccess;
