import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ colour }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13">
      <path
        id="Button_Arrow_Grey"
        data-name="Button Arrow Grey"
        d="M0-953.5,8-960v3h7v7H8v3Z"
        transform="translate(15 -947) rotate(180)"
        fill={colour}
      />
    </svg>
  );
};

Arrow.propTypes = {
  colour: PropTypes.string,
};

export default Arrow;
