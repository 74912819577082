import React from 'react';
import PropTypes from 'prop-types';
import './RowWrapper.styles.scss';

const RowWrapper = ({ children, overrideStyles = '' }) => {
  return (
    <div data-testid="RowWrapper" className={`flex flex-col md:flex-row gap-6 min-h-[500px] ${overrideStyles}`}>
      {children}
    </div>
  );
};

RowWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  overrideStyles: PropTypes.string,
};

export default RowWrapper;
