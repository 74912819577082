export const MEMBERSHIP_TYPES = {
  social: 'social',
  club: 'club',
  elite: 'elite',
};

export const MEMBERSHIPS_MAP = {
  '1': 'social',
  '2': 'club',
  '3': 'elite',
};


export const MEMBERSHIP_CARD_DETAILS = [
  {
    title: 'Social',
    variant: 'social',
    subtitle: 'For entry level karters',
    price: '£7.99',
    benefits: [
      'For adults aged 16+',
      'Loyalty rewards points',
      '20% off full-priced karting',
      'Free cotton balaclava',
      'Free pair of gloves',
      'Access to Monday night Members 342 events',
    ],
  },
  {
    title: 'Club',
    variant: 'club',
    subtitle: 'For enthusiastic karters',
    price: '£24.99',
    benefits: [
      'For adults aged 16+',
      'Loyalty rewards points',
      '25% off full-priced karting when booking online',
      'Free race on your birthday*',
      'Annual safety briefing',
      'Access to Club and Social Member events',
      'Free cotton balaclava',
    ],
  },
  {
    title: 'Elite',
    variant: 'elite',
    subtitle: 'For elite karters',
    price: '£34.99',
    benefits: [
      'For adults aged 16+',
      'Loyalty rewards points',
      '25% off full-priced karting when booking online',
      'Free race on your birthday*',
      'Annual safety briefing',
      'Access to All member events',
      'Motorsport UK-K1 license and benefits',
      'Entry rights to BIKC',
    ],
  },
];
