import React from 'react';
import PropTypes from 'prop-types';
import './BodyText.styles.scss';

import { sizeVariants, textColourVariants, trackingVariants, weightVariants } from 'constants/typography';

const BodyText = ({
  colour = 'white',
  text,
  size = 'md',
  uppercase = false,
  weight = 'normal',
  tracking = 'normal',
}) => {
  return (
    <p
      data-testid="BodyText"
      className={`${sizeVariants[size]} ${textColourVariants[colour]} ${weightVariants[weight]} ${
        trackingVariants[tracking]
      } ${uppercase ? 'uppercase' : ''}`}
    >
      {text}
    </p>
  );
};

BodyText.propTypes = {
  colour: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  uppercase: PropTypes.bool,
  weight: PropTypes.oneOf(['thin', 'book', 'normal', 'bold']),
  tracking: PropTypes.oneOf(['normal', '2.4px', '2.17px', '1.2px', '0.45px', '2.28px', '0.55px', '1.08px']),
};

export default BodyText;
