import React from 'react';
import PropTypes from 'prop-types';
import { buttonLinkHoverVariants, textColourVariants } from 'constants/typography';

import './ButtonListItem.styles.scss';

const ButtonListItem = ({ text, onClick, variant, isSelected }) => {
  return (
    <button
      data-testid="ButtonListItem"
      onClick={onClick}
      className={`uppercase ${buttonLinkHoverVariants[variant]} flex items-end font-book ${
        isSelected ? `${textColourVariants[variant]} hover:opacity-60` : 'text-gray'
      }`}
    >
      <p className="text-xl leading-none text-left">{text}</p>
    </button>
  );
};

ButtonListItem.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['social', 'club', 'elite', 'white']),
  isSelected: PropTypes.bool,
};

export default ButtonListItem;
