import React from 'react';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';
import { COLOURS } from 'constants/colours';

import './CodeInputGroup.styles.scss';

const CodeInputGroup = ({ value, onChange }) => {
  return (
    <ReactCodeInput
      onChange={onChange}
      type="text"
      fields={6}
      value={value}
      inputStyle={{
        borderWidth: '1px',
        borderColor: COLOURS.gray,
        borderRadius: '8px',
        backgroundColor: COLOURS.black,
        width: '13%',
        height: '60px',
        outline: 'none',
        textAlign: 'center',
        color: COLOURS.gray,
        marginRight: '8px',
        fontWeight: 'bold',
        fontSize: '31px',
        maxWidth: '60px',
      }}
    />
  );
};

CodeInputGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default CodeInputGroup;
