import React from 'react';
import PropTypes from 'prop-types';
import './MembershipLeftContainer.styles.scss';
import MembershipLogoWithText from 'components/MembershipLogoWithText/MembershipLogoWithText';
import KartImage from 'components/KartImage/KartImage';

const MembershipLeftContainer = ({ variant, price }) => {
  return (
    <div data-testid="MembershipLeftContainer" className="relative">
      <div className="absolute w-[150px] h-[150px] left-[38px]">
        <MembershipLogoWithText variant={variant} price={price} />
      </div>
      <div className="absolute">
        <KartImage width="600px" />
      </div>
    </div>
  );
};

MembershipLeftContainer.propTypes = {
  variant: PropTypes.oneOf(['social', 'club', 'elite']),
  text: PropTypes.string,
  price: PropTypes.string,
};

export default MembershipLeftContainer;
