import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import BackArrow from 'assets/back-arrow.svg';
import CloseButton from 'assets/close-button.svg';
import './BackButton.styles.scss';

const BackButton = ({ variant, closeUrl }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (closeUrl) {
      return navigate(closeUrl);
    }
    navigate(-1);
  }, []);
  return (
    <button data-testid="BackButton" className="flex text-gray items-center gap-2" onClick={handleClick}>
      <img src={variant === 'close' ? CloseButton : BackArrow} alt={variant === 'close' ? 'close-icon' : 'back-icon'} />
      <p>{variant === 'close' ? 'Close' : 'Back'}</p>
    </button>
  );
};

BackButton.propTypes = {
  variant: PropTypes.oneOf(['back', 'close']),
  closeUrl: PropTypes.string,
};

export default BackButton;
