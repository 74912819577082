import { useEffect, useRef } from 'react';

export const useComponentDidUpdate = ({ callback, deps = [] }) => {
  const firstMount = useRef(null);
  useEffect(() => {
    if (firstMount.current) {
      callback();
    } else {
      firstMount.current = true;
    }
  }, deps);
};
