import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import BodyText from 'components/BodyText/BodyText';
import ButtonListGroup from 'components/ButtonListGroup/ButtonListGroup';
import Divider from 'components/Divider/Divider';
import Button from 'components/Button/Button';
import SingleColumnWrapper from 'components/SingleColumnWrapper/SingleColumnWrapper';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

import { PATH_MEMBERSHIP } from 'routes/paths';
import { PAYMENT_ACTIONS } from 'constants/payments';

import { useAuthContext } from 'hooks/useAuthContext';
import { useHasSubscriptionRedirect } from 'hooks/useHasSubscriptionRedirect';

import { getLocations } from 'api';

const MembershipChooseVenue = (props) => {
  const { new_membership_type, action } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { subscription, auth_token, setLocations, locations, userSelectedLocation, setUserSelectedLocation } =
    useAuthContext();

  const subscriptionLocation = subscription?.sms_location;
  const [isLoading, setIsLoading] = useState(false);

  const fetchLocations = useCallback(async () => {
    try {
      if (auth_token) {
        setIsLoading(true);
        const data = await getLocations(auth_token);
        setLocations({ locations: data?.locations });
      }
    } catch (err) {
      navigate(PATH_MEMBERSHIP.getMembershipError(), {
        state: {
          title: 'Something went wrong when fetching locations',
          retryUrl: `${pathname}`,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, [auth_token]);

  useEffect(() => {
    fetchLocations();
  }, [auth_token]);

  const onClick = useCallback(() => {
    navigate(PATH_MEMBERSHIP.getMembershipPayRoute(new_membership_type, action, userSelectedLocation));
  }, [userSelectedLocation, new_membership_type, action]);

  let title = 'Choose your location';

  if (action === PAYMENT_ACTIONS.renew) {
    const customerLocation = locations.find((l) => l?.client_key === subscriptionLocation)?.name;
    title = customerLocation ? `Your current location is ${customerLocation}` : title;
  }

  useHasSubscriptionRedirect();

  if (isLoading) {
    return (
      <SingleColumnWrapper>
        <LoadingSpinner />
      </SingleColumnWrapper>
    );
  }

  return (
    <SingleColumnWrapper>
      <div className="w-full">
        <div>
          <BodyText text={title} size="2xl" colour={new_membership_type} uppercase tracking="2.17px" />
          <div className="my-2">
            <Divider />
          </div>
          <div className="mt-2 mb-6">
            <BodyText colour="gray" text="To change this select a different location and click confirm" weight="book" />
          </div>
        </div>
        <div>
          <ButtonListGroup
            cities={locations}
            variant={new_membership_type}
            onSelect={setUserSelectedLocation}
            selected={userSelectedLocation}
          />
        </div>
        <div className="my-6">
          <Divider />
        </div>
        <div className="w-full flex justify-center">
          <Button
            text="Confirm"
            onClick={onClick}
            colour={new_membership_type}
            variant="standard"
            arrow
            disabled={!userSelectedLocation}
          />
        </div>
      </div>
    </SingleColumnWrapper>
  );
};

MembershipChooseVenue.propTypes = {};

export default MembershipChooseVenue;
