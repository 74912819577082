import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import RowWrapper from 'components/RowWrapper/RowWrapper';
import MembershipLeftContainer from 'components/MembershipLeftContainer/MembershipLeftContainer';
import StripeExistingCard from 'components/StripeExistingCard/StripeExistingCard';
import StripeNewCard from 'components/StripeNewCard/StripeNewCard';

import { useAuthContext } from 'hooks/useAuthContext';
import { useHasSubscriptionRedirect } from 'hooks/useHasSubscriptionRedirect';

import { PATH_MEMBERSHIP, PATH_PAYMENT_METHODS } from 'routes/paths';

import { PAYMENT_ACTIONS } from 'constants/payments';
import { MEMBERSHIP_CARD_DETAILS } from 'constants/membership';

import { postPaymentConfirmation } from 'api';

import { setMembershipRefKey } from 'utils/localStorage';

const MembershipMakePayment = (props) => {
  const { pathname } = useLocation();
  const { new_membership_type, action, venue } = useParams();

  const { subscription, payment_methods = [], auth_token, setMembershipRef, membership_ref } = useAuthContext();

  const [isPayNowLoading, setIsPayNowLoading] = useState(false);

  const navigate = useNavigate();
  const price = MEMBERSHIP_CARD_DETAILS.find((c) => c?.variant === new_membership_type)?.price;

  const onPayNow = useCallback(async () => {
    try {
      setIsPayNowLoading(true);
      const res = await postPaymentConfirmation(auth_token, { subscription_type: new_membership_type });
      if (!res.success) {
        throw new Error('Unable to make payment');
      }
      setMembershipRef(new_membership_type);
      setMembershipRefKey(new_membership_type);
      navigate(PATH_MEMBERSHIP.getMembershipPaySuccess(new_membership_type, PAYMENT_ACTIONS.new, venue));
    } catch (err) {
      navigate(PATH_MEMBERSHIP.getMembershipPayError(new_membership_type, PAYMENT_ACTIONS.new, venue), {
        state: {
          title: err?.error_message || 'Something went wrong when making payment',
          retryUrl: `/${PATH_MEMBERSHIP.getMembershipPayRoute(new_membership_type, PAYMENT_ACTIONS.new, venue)}`,
        },
      });
    } finally {
      setIsPayNowLoading(false);
    }
  }, [auth_token]);

  const handleChangePaymentType = useCallback(() => {
    navigate(PATH_PAYMENT_METHODS.add);
  }, []);

  useHasSubscriptionRedirect();

  const card = payment_methods[0];

  return (
    <RowWrapper overrideStyles="w-full">
      <div className="lg:w-3/6 hidden lg:block">
        <MembershipLeftContainer variant={new_membership_type} price={price} />
      </div>
      <div className="w-full lg:w-3/6">
        {card ? (
          <StripeExistingCard
            variant={new_membership_type}
            card={card}
            onChangePaymentType={handleChangePaymentType}
            onPayNow={onPayNow}
            isPayNowLoading={isPayNowLoading}
          />
        ) : (
          <StripeNewCard
            variant={new_membership_type}
            price={price}
            stripeSetup="pi"
            redirectTo={`${PATH_MEMBERSHIP.getMembershipPaySuccess(new_membership_type, action, venue)}`}
          />
        )}
      </div>
    </RowWrapper>
  );
};

MembershipMakePayment.propTypes = {};

export default MembershipMakePayment;
