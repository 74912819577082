import React from 'react';
import PropTypes from 'prop-types';

const TickSVG = ({ colour }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.111" height="15.363" viewBox="0 0 16.111 15.363">
      <path
        id="Tick2"
        d="M15.965,7.995,14.228,9.459a.41.41,0,0,0-.134.412l.545,2.206a.41.41,0,0,1-.369.508L12,12.747a.41.41,0,0,0-.351.255l-.855,2.1a.41.41,0,0,1-.6.194L8.272,14.1a.411.411,0,0,0-.433,0L5.909,15.3a.41.41,0,0,1-.6-.194L4.458,13a.41.41,0,0,0-.351-.255l-2.266-.163a.41.41,0,0,1-.369-.508l.545-2.206a.41.41,0,0,0-.134-.412L.146,7.995a.41.41,0,0,1,0-.627L1.883,5.9a.41.41,0,0,0,.134-.412L1.472,3.286a.41.41,0,0,1,.369-.508l2.266-.163a.411.411,0,0,0,.351-.255L5.313.255a.41.41,0,0,1,.6-.194l1.929,1.2a.41.41,0,0,0,.433,0L10.2.062a.41.41,0,0,1,.6.194l.855,2.105A.411.411,0,0,0,12,2.615l2.266.163a.41.41,0,0,1,.369.508l-.545,2.206a.41.41,0,0,0,.134.412l1.738,1.464a.41.41,0,0,1,0,.627"
        transform="translate(0 0)"
        fill={colour}
        fillRule="evenodd"
      />
    </svg>
  );
};

TickSVG.propTypes = {
  colour: PropTypes.string,
};

export default TickSVG;
