export const sizeVariants = {
  xs: 'text-xs', // 0.875rem / 14px
  sm: 'text-sm', // 1.125rem / 16px
  md: 'text-base', // 1.125rem / 18px
  lg: 'text-lg', // 1.25rem / 20px
  xl: 'text-xl', // 1.5rem /  24px
  '2xl': 'text-xl md:text-2xl', // 1.938rem / 31px
  '3xl': 'text-xl md:text-3xl', // 2.5rem / 40px
  '4xl': 'text-3xl md:text-4xl', // 4.125rem / 66px
  '5xl': 'text-3xl md:text-5xl', // 4.75rem / 76px
  '22px': 'text-[22px]',
};

export const weightVariants = {
  thin: 'font-thin',
  normal: 'font-normal',
  book: 'font-book',
  bold: 'font-bold',
};

// letter spacing
export const trackingVariants = {
  '0.45px': 'tracking-[0.45px]',
  '0.55px': 'tracking-[0.55px]',
  normal: 'tracking-normal',
  '1.08px': 'tracking-[1.08px]',
  '1.17px': 'tracking-[1.17px]',
  '1.2px': 'tracking-[1.2px]',
  '1.98px': 'tracking-[1.98px]',
  '2.17px': 'tracking-[2.17px]',
  '2.4px': 'tracking-[2.4px]',
  '2.28px': 'tracking-[2.28px]',
};

export const textColourVariants = {
  white: 'text-white',
  black: 'text-black',
  gray: 'text-gray',
  social: 'text-social',
  club: 'text-club',
  elite: 'text-elite',
};

export const buttonLinkHoverVariants = {
  social: 'hover:text-social',
  club: 'hover:text-club',
  elite: 'hover:text-elite',
};
