import React from 'react';
import PropTypes from 'prop-types';

import MembershipLogo from 'components/MembershipLogo/MembershipLogo';
import BodyText from 'components/BodyText/BodyText';
import Price from 'components/Price/Price';

import './MembershipLogoWithText.styles.scss';

const MembershipLogoWithText = ({ variant, text, price }) => {
  return (
    <div data-testid="MembershipLogoWithText" className="flex flex-col justify-center items-center w-full">
      <div className="h-[200px] md:h-[160px]">
        <MembershipLogo variant={variant} />
      </div>
      <div className="mt-4 md:mt-2">
        {text && <BodyText size="xl" colour={variant} text={text} weight="bold" tracking="0.45px" uppercase />}
        {price && <Price size="xl" colour={variant} price={price} />}
      </div>
    </div>
  );
};

MembershipLogoWithText.propTypes = {
  variant: PropTypes.oneOf(['social', 'club', 'elite']),
  text: PropTypes.string,
  price: PropTypes.string,
};

export default MembershipLogoWithText;
