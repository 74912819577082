import React from 'react';
import PropTypes from 'prop-types';
import './MembershipHeader.styles.scss';
import Logo from 'components/Logo/Logo';
import BodyText from 'components/BodyText/BodyText';

const MembershipHeader = (props) => {
  return (
    <div data-testid="MemebershipHeader" className="flex flex-col items-center justify-center w-full">
      <Logo height="h-[80px]" />
      <BodyText text="My Membership" size="xl" colour="gray" tracking="2.4px" uppercase />
    </div>
  );
};

MembershipHeader.propTypes = {};

export default MembershipHeader;
