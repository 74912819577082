import React from 'react';
import PropTypes from 'prop-types';

import CardHeader from 'components/CardHeader/CardHeader';
import CardList from 'components/CardList/CardList';
import Button from 'components/Button/Button';

import './MembershipCard.styles.scss';

const MembershipCard = ({ title, subtitle, price, benefits, variant, onClick }) => {
  return (
    <div
      data-testid="MembershipCard"
      className={`h-[390px] md:h-[434px] max-w-[70vw] min-w-[260px] lg:min-w-[300px] max-h-[50vh] border-gray bg-black border-2 p-4 md:p-5 lg:p-6 pb-0 flex flex-col transition-transform cursor-pointer opacity-60 hover:scale-110 hover:z-10 hover:opacity-100 hover:shadow-md relative`}
    >
      <div>
        <CardHeader variant={variant} title={title} subtitle={subtitle} price={price} />
      </div>
      <div className="mt-2">
        <CardList list={benefits} variant={variant} />
      </div>
      <div className="flex flex-1 justify-center items-center">
        <Button onClick={() => onClick(variant)} text="Select Membership" variant="standard" colour={variant} />
      </div>
    </div>
  );
};

MembershipCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.string,
  benefits: PropTypes.array,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

export default MembershipCard;
