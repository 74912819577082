import React from 'react';
import PropTypes from 'prop-types';

const Root = ({ children }) => {
  return (
    <div data-testid="Root" className="App min-h-[100vh] overflow-hidden font-bebas font-normal">
      {children}
    </div>
  );
};

Root.propTypes = {
  children: PropTypes.node,
};

export default Root;
