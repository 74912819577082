import React from 'react';
import PropTypes from 'prop-types';
import './ButtonListGroup.styles.scss';
import ButtonList from 'components/ButtonList/ButtonList';

const COLUMNS = 4;

const ButtonListGroup = ({ cities, variant, onSelect, selected }) => {
  const columns = [...Array(COLUMNS).keys()].map((c) => cities.filter((_, i) => i % COLUMNS === c));

  return (
    <div
      data-testid="ButtonListGroup"
      className="flex flex-col md:flex-row gap-8 overflow-scroll h-[300px] overflow-x-hidden md:h-auto md:overflow-hidden"
    >
      {columns.map((list, i) => {
        return <ButtonList list={list} onClick={onSelect} variant={variant} key={`${i}_column`} selected={selected} />;
      })}
    </div>
  );
};

ButtonListGroup.propTypes = {
  cities: PropTypes.array,
  variant: PropTypes.oneOf(['social', 'club', 'elite']),
  onSelect: PropTypes.func,
  selected: PropTypes.string,
};

export default ButtonListGroup;
