import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { sizeVariants, textColourVariants } from 'constants/typography';
import './Link.styles.scss';

const Link = ({ url, bold, underline, colour = 'gray', text, size = 'md', onClick }) => {
  const navigate = useNavigate();
  const handleClick = useCallback((e) => {
    e?.preventDefault();
    if (url) {
      return navigate(url);
    }
    onClick();
  }, []);

  return (
    <a
      data-testid="Link"
      href={url}
      onClick={handleClick}
      className={`${bold ? 'font-bold' : ''} ${textColourVariants[colour]} ${sizeVariants[size]} ${
        underline ? 'underline underline-offset-4' : ''
      } hover:cursor-pointer hover:text-white`}
    >
      {text}
    </a>
  );
};

Link.propTypes = {
  url: PropTypes.string,
  bold: PropTypes.bool,
  underline: PropTypes.bool,
  colour: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

export default Link;
