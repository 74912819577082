import React from 'react';
import PropTypes from 'prop-types';
import './AddPaymentLeftContainer.styles.scss';
import Divider from 'components/Divider/Divider';
import BodyText from 'components/BodyText/BodyText';

const AddPaymentLeftContainer = (props) => {
  return (
    <div
      data-testid="AddPaymentLeftContainer"
      className="w-full flex flex-col items-center text-center md:block md:w-3/6 h-full md:mt-10"
    >
      <div className="w-[300px]">
        <div className="my-2">
          <Divider />
        </div>
        <BodyText text="Update payment method" weight="book" size="2xl" colour="white" tracking="2.17px" uppercase />
      </div>
    </div>
  );
};

AddPaymentLeftContainer.propTypes = {};

export default AddPaymentLeftContainer;
