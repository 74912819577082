import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import './MembershipCardGroup.styles.scss';

import MembershipCard from 'components/MembershipCard/MembershipCard';

import { MEMBERSHIP_CARD_DETAILS } from 'constants/membership';
import { PATH_MEMBERSHIP } from 'routes/paths';
import { PAYMENT_ACTIONS } from 'constants/payments';

const MembershipCardGroup = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 780px)' });
  const navigate = useNavigate();
  const onClick = useCallback((variant) => {
    navigate(PATH_MEMBERSHIP.getChooseVenueRoute(variant, PAYMENT_ACTIONS.new));
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 1.66,
  };

  const membershipCards = useMemo(() =>
    MEMBERSHIP_CARD_DETAILS.map(({ title, subtitle, price, benefits, variant }) => {
      return (
        <MembershipCard
          title={title}
          subtitle={subtitle}
          price={price}
          benefits={benefits}
          variant={variant}
          onClick={onClick}
          key={variant}
        />
      );
    })
  );

  return (
    <div data-testid="MemebershipCardGroup" className="py-6 md:p-0 md:flex md:gap-2 md:justify-center">
      {isMobile ? <Slider {...settings}>{membershipCards}</Slider> : membershipCards}
    </div>
  );
};

MembershipCardGroup.propTypes = {};

export default MembershipCardGroup;
