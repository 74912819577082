import React from 'react';
import PropTypes from 'prop-types';
import BounceLoader from 'react-spinners/BounceLoader';
import { COLOURS } from 'constants/colours';

export const LoadingSpinner = ({ colour = COLOURS.white, size = 30 }) => {
  return <BounceLoader color={colour} loading={true} size={size} aria-label="Loading Spinner" data-testid="loader" />;
};

LoadingSpinner.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.number,
};
