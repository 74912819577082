import React from 'react';
import PropTypes from 'prop-types';

const PaymentCardField = ({ label, value, isExpired }) => {
  return (
    <div className={`flex flex-col text-black ${isExpired ? 'text-warning' : ''}`}>
      <p className="text-xs uppercase">{label}</p>
      <p className="text-lg">{value}</p>
    </div>
  );
};

PaymentCardField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isExpired: PropTypes.bool
};

export default PaymentCardField;
