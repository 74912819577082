import axiosInstance from 'utils/axios';

export const postValidateUser = async (data) => {
  const res = await axiosInstance.post('/validate-user', data);
  return res.data;
};

export const postTokenVerify = async (data) => {
  const res = await axiosInstance.post('/token/verify', data);
  return res.data;
};

export const postTokenRefresh = async (data) => {
  const res = await axiosInstance.post('/token/refresh', data);
  return res.data;
};

export const getLocations = async (auth_token) => {
  const res = await axiosInstance.get(`/locations/${auth_token}`);
  return res.data;
};

export const getUserProfile = async (client_key, auth_token, membership_ref) => {
  const res = await axiosInstance.get(
    `/profile/${client_key}/${auth_token}${membership_ref ? `?membership_ref=${membership_ref}` : ''}`
  );
  return res.data;
};

export const getMemberships = async (auth_token) => {
  const res = await axiosInstance.get(`/memberships/${auth_token}`);
  return res.data;
};

export const getPaymentMethods = async (auth_token) => {
  const res = await axiosInstance.get(`payment-methods/${auth_token}`);
  return res.data;
};

export const postPaymentMethod = async (auth_token, data) => {
  const res = await axiosInstance.post(`payment-methods/${auth_token}`, { data });
  return res.data;
};

// DELETE Payment Method: Will need payment_method_id, new_payment_method_id (optional) and subscription_type (optional) keys in the data
export const deletePaymentMethod = async (auth_token, data) => {
  const res = await axiosInstance.delete(`payment-methods/${auth_token}`, { data });
  return res.data;
};

export const postCheckout = async (auth_token, data) => {
  const res = await axiosInstance.post(`checkout/${auth_token}`, data);
  return res.data;
};
// subscription type
export const postPaymentConfirmation = async (auth_token, data) => {
  const res = await axiosInstance.post(`payment/${auth_token}/confirm`, data);
  return res.data;
};
